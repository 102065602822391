.b-summary_section {
	background-color: $color-bg;

	@include media(lg-up) {
		position: sticky;
		top: 120px;
	}

	@include media(xl) {
		top: 80px;
	}

	&.m-payments {
		margin-bottom: 0;
	}

	&.m-simple {
		background-color: $color-bg-tabs;
		border: $global-line solid $color-border-tabs;
		margin-bottom: 20px;
		padding: 14px 20px;
	}

	&-title {
		font-weight: 500;
		margin-bottom: 24px;
	}

	&.m-simple &-title {
		font-size: 15px;
	}

	&-message {
		display: flex;
		font-size: 13px;
		gap: 5px;
		margin: 10px 0 0;

		&.m-expanded {
			background-color: $color-natural;
			border-bottom: 1px solid $color-mid-grey;
			margin-bottom: 15px;
			margin-top: -1px;
			padding-bottom: 10px;

			@include media(sm) {
				border: none;
				border-top: 1px solid $color-mid-grey;
				margin-bottom: 0;
				padding-bottom: 0;
				padding-top: 8px;
			}
		}

		&.m-no_store {
			@include media(sm) {
				border: none;
			}
		}

		&.m-warning {
			margin-bottom: 8px;
			margin-top: 5px;
		}

		svg {
			fill: $color-action-obj;
			flex-shrink: 0;
			height: 18px;
			width: 18px;
		}

		+ .b-summary_actions {
			margin-top: 20px;

			.m-expanded & {
				margin-top: 5px;
			}
		}

		.b-coupon-link {
			color: $color-burgundy;
		}
	}

	&-location {
		font-weight: 600;
	}

	&-notice {
		display: block;
		margin-top: 5px;
	}
}
