.b-account {
	color: $color-primary;
	display: flex;
	flex-wrap: wrap;
	margin: auto;

	&.m-login {
		@include media (md-up) {
			max-width: 350px;
		}

		.b-tab_panel {
			padding: 0 15px;

			@include media (lg-up) {
				padding: 0 35px;
			}
		}
	}

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 30px;
		text-align: center;
	}

	&.m-account &-title {
		text-align: left;
	}

	&-empty_state {
		margin: 15px 0 40px;
	}

	&-register {
		margin: 0 -5px;

		@include media (md-up) {
			margin: 0 -55px;
		}
	}
}
