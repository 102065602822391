.b-summary_group {
	font-size: 15px;

	&-item {
		margin-top: 24px;
	}

	&-title {
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 16px;
		width: 100%;
	}

	&-caption {
		color: $color-text-dimmed; // used on confirmation page. Like: * item are listed under your items.
	}

	&-message {
		color: $color-success;
		font-weight: 500;
		margin-top: 20px;
	}

	&-description {
		font-size: 13px;
		line-height: 1.45;
		margin-bottom: 20px;
	}
}

.b-summary_group.m-columns { // Used on checkout step summary, when it should be columned
	@include media(md-down) {
		.b-summary_group-item + .b-summary_group-item {
			margin-top: 30px;
		}
	}

	@include media(lg-up) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.b-summary_group-item {
		margin-top: 0;

		@include media(lg-up) {
			width: calc(50% - #{12px});
		}

		&.m-gift {
			margin-top: 24px;
			width: 100%;
		}
	}
}
