.b-registration {
	&-date {
		display: flex;
		flex-wrap: wrap;

		.b-form_section-label {
			margin-bottom: 0;
		}
	}

	&-inner {
		background: $color-bg-ma;
		border-radius: $global-radius;
		margin-bottom: 20px;
		padding: 20px;

		&.m-registration {
			background-color: initial;
			font-size: 13px;
			padding: 0;

			.b-form_section-label {
				padding-right: 50px;
			}

			.b-form_section.m-required .b-form_section-label::after {
				display: none;
			}
		}
	}

	&-item {
		margin-right: 10px;
		max-width: 46px;

		&.m-last {
			max-width: 75px;
		}
	}

	&-gift_message {
		font-size: 11px;
		line-height: 16px;
		margin-top: 4px;

		.b-account_details & {
			margin-bottom: 20px;
		}
	}

	&-marketing {
		color: $color-primary;
		font-size: 13px;
		line-height: 16px;
	}

	&-title {
		font-weight: 500;
		margin-bottom: 10px;
	}

	&-text {
		line-height: 18px;
		margin-bottom: 10px;
	}

	&-link {
		font-weight: 600;
		line-height: 17px;
		text-decoration: underline;
	}

	.b-checkbox {
		@include media(sm) {
			align-items: flex-start;
		}
	}
}
